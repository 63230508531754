<template>
  <panel title="">
    <draggable
      v-model="list"
      class="list-group list-group-flush cursor-move"
      tag="ul"
    >
      <transition-group type="transition" name="flip-list">
        <b-list-group-item
          v-for="listItem in list1"
          :key="listItem.id"
          tag="li"
        >
          <div class="d-flex">
            <b-avatar :text="listItem.name" />
            <div class="ml-25">
              <b-card-text class="font-weight-bold mb-0">
                {{ listItem.name }}
              </b-card-text>
              <small>{{ listItem.email }}</small>
            </div>
          </div>
        </b-list-group-item>
      </transition-group>
    </draggable>
  </panel>
</template>

<script>
import draggable from "vuedraggable"
export default {
  components: {
    draggable,
  },
  data() {
    return {
      list1: [],
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.list1 = await this.api.get(`/api/timelineData/HacsIntegration`)
      } catch (error) {
        console.error(error)
        return ""
      }
    },
  },
}
</script>

<style>
.list1-group-item {
  transition: all 1s;
}
</style>
